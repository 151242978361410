import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { UtilService } from './util.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl = environment.apiUrl + environment.version
  public subject = new BehaviorSubject<any>(null);
  _id:any
  adminProfile:any;


  constructor(
    private http: HttpClient,
    private util: UtilService
  ) {
    this.http.get(`${this.baseUrl}admin/profile`).pipe(
      catchError(this.util.handleError('getAdminProfile', []))
    ).subscribe((data:any) => {
      // this._id = data.data.iOwnerId._id
      this.adminProfile = data
      this.subject.next(this.adminProfile)
    })
   }

  updateProfile(data) {
     return this.http.post(`${this.baseUrl}admin/profile`, data).pipe(
      map((user:any) => {
        this.http.get(`${this.baseUrl}admin/profile`).pipe(
          catchError(this.util.handleError('getAdminProfile', []))
        ).subscribe((data:any) => {
          this.adminProfile = data
          this.subject.next(this.adminProfile)
        })
        return user;
      })
    );

  }

  changePassword(data) {
    return this.http.put
      (`${this.baseUrl}admin/profile/changePassword`, data).pipe(
        catchError(this.util.handleError('changePassword', []))
      )
  }

}
